import React from 'react';
import styles from './styles.module.css';

const ContentBreak = () => {
    return (
        <div className={styles.contentBreakContainer}/>
    );
}

export default ContentBreak;
